import React, { useEffect, useState } from "react";
import PopupLayout from "../../components/templetes/popupLayout";
import { StyledButton } from "../../components/atoms/button";
import { ValidationInput } from "../../components/atoms/validation/ValidationInput";
import { TWO_FACTOR_AUTH_KEY } from "../TwoFactorAuthKey";
const TwoFactorAuthPopup = ({ title, target,
    requestOtp = () => { },
    closePopup = () => { },
    onSubmit = () => { },
    checkExpiration = () => { }
}) => {
    const [code, setCode] = useState("")
    const [timeout, setTimeoutJob] = useState(null)
    const [expiration, setExpiration] = useState(null)
    const [error, setError] = useState(null)
    const [isSendingOtp, setSendingOTP] = useState(false)
    const checkTwoFactorAuthCodeExpiration = () => {
        setTimeoutJob(setTimeout(() => {
            checkExpiration((result) => {
                setExpiration(result.view)
                if (result.expiration > 0)
                    checkTwoFactorAuthCodeExpiration()
                else{
                    setError("code is expired.")
                    setExpiration(null)
                    sessionStorage.removeItem(TWO_FACTOR_AUTH_KEY)
                }
            })
        }, 1000));
    }
    useEffect(() => {
        return () => {
            clearTimeout(timeout)
        }
    }, [timeout])
    const submitTwoFactorAuthCode = () => {
        onSubmit({
            code: code
        }, (result) => {
            setError(result.message)
        })
    }
    return (
        <PopupLayout
            theme="primary"
            data-size="lg"
            title={title}
            closePopup={closePopup}
            button={
                <StyledButton
                    type="text"
                    data-bg="primary"
                    size="md"
                    onClick={submitTwoFactorAuthCode}
                >
                    Confirm
                </StyledButton>
            }>
            <ValidationInput
                label="Two-Factor Authentication Method"
                style={{ cursor: 'default' }}
                disabled value={target} onChange={() => { }}>

            </ValidationInput>
            <div style={{ display: 'flex', width: "calc(100%)" }} >
                <div style={{ flexGrow: 1 }}>
                    <ValidationInput
                        label="Code"
                        value={code}
                        onKeyDown={e => {
                            if (e.code == "Enter" || e.code == "NumpadEnter")
                                submitTwoFactorAuthCode()
                        }}
                        onChange={e => {
                            setCode(e.target.value)
                        }} />
                    {
                        expiration != null &&
                        <p style={{ height: 30, textAlign: 'right', marginRight: 15 }}>Expiration : {`${expiration}`} sec</p>
                    }
                    {
                        error != null &&
                        <p style={{ height: 30, textAlign: 'right', marginRight: 15, color: 'hsla(0, 79%, 63%, 1)' }}>Message : {error}</p>
                    }
                </div>
                <StyledButton
                    style={{ marginTop: 29, cursor: !isSendingOtp ? 'pointer' : 'progress' }}
                    type="text"
                    data-bg={isSendingOtp ? "lightgray" : "primary"}
                    size="md"
                    onClick={() => {
                        if (isSendingOtp)
                            return;
                        setSendingOTP(true)
                        requestOtp(() => {
                            setSendingOTP(false)
                            setError(null)
                            checkTwoFactorAuthCodeExpiration()
                        })
                    }}
                > Send
                </StyledButton>
            </div>

        </PopupLayout>
    )
}
export default TwoFactorAuthPopup