import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { initAxios } from '../utils/API'
import AuthReducer, { whoami } from '../pages/AuthReducer'
import { TWO_FACTOR_AUTH_KEY } from '../pages/TwoFactorAuthKey';

const createHashHistory = require('history').createHashHistory;
export const history = createHashHistory();
const RootReducer = combineReducers({
  router: connectRouter(history),
  auth: AuthReducer
})
const composeEnhancers =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

initAxios(history)
const enhancer = composeEnhancers(
  applyMiddleware(
    thunk,
    routerMiddleware(history)
  ),
  // other store enhancers if any
);
const store = createStore(RootReducer, enhancer)
whoami((url) => window.location.replace(url))
export default store
sessionStorage.removeItem(TWO_FACTOR_AUTH_KEY)